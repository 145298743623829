import { Component, Input, OnInit } from '@angular/core'
import { NavigationSkipped, Router } from '@angular/router'
import { HttpErrorResponse } from '@angular/common/http'
import { Store } from '@ngxs/store'
import { Navigate } from '@ngxs/router-plugin'
import { SelectSnapshot } from '@ngxs-labs/select-snapshot'
import { Sort } from '@angular/material/sort'
import {
  catchError,
  combineLatest,
  filter,
  finalize,
  first,
  map,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap
} from 'rxjs'

import { ToggleUserProfile } from '../state/user-profile.actions'
import { BaseComponent } from '../../../components/base/base.component'
import { UserProfileComponent } from '../user-profile.component'
import { Table } from '../../../../core/classes/table/table'
import { IndividualPaymentResponse } from '../../../../module/dashboard/tabs/economy-tab/individual-payments/interfaces/individual-payment-response'
import { Columns } from '../../../../core/enums/table/columns'
import { PaymentTypes } from '../../../../core/enums/economy/payment-types'
import { PaidStatus } from '../../../../module/dashboard/tabs/economy-tab/enums/paid-status'
import { OrderStatus } from '../../../../module/dashboard/tabs/economy-tab/interfaces/order-status'
import { DateFormat } from '../../../../core/enums/global/date-format'
import {
  EconomyTabState,
  UserPermissions
} from '../../../../module/dashboard/tabs/economy-tab/states/economy-tab.state'
import { Constants } from '../../../../constants/constants'
import { EconomyTabService } from '../../../../core/api'
import { Organization } from '../../../../core/interfaces/organization/organization'
import { DashboardState } from '../../../../module/dashboard/states/dashboard.state'
import { PaidStatusFilterValues } from '../../../../module/dashboard/tabs/economy-tab/enums/paid-status-filter-values'
import { FilterNames } from '../../../../module/dashboard/tabs/economy-tab/enums/filter-names'
import { UtilsService } from '../../../../core/services'

@Component({
  selector: 'app-unpaid-payments',
  templateUrl: 'unpaid-payments.component.html',
  styleUrl: 'unpaid-payments.component.scss',
  standalone: false
})
export class UnpaidPaymentsComponent extends BaseComponent implements OnInit {
  @SelectSnapshot(EconomyTabState.userEconomyPermissions) userEconomyPermissions: UserPermissions
  @SelectSnapshot(DashboardState.organization) organization: Organization

  @Input({ required: true }) hostComponent: UserProfileComponent

  table: Table<IndividualPaymentResponse> = new Table({
    columns: [
      Columns.PaymentName,
      Columns.Group,
      Columns.Type,
      Columns.GrossAmount,
      Columns.PublishDate,
      Columns.DueDate,
      Columns.PaymentStatus,
      Columns.LastReminder,
      Columns.LastInvoice,
      Columns.Comments,
      Columns.Actions
    ]
  })
  selectedPayment: IndividualPaymentResponse | null = null
  isNewReminderOpen: boolean = false
  isReminderHistoryOpen: boolean = false
  isNewInvoiceOpen: boolean = false
  isInvoiceHistoryOpen: boolean = false
  isEditUserPaymentOpen: boolean = false

  private dataUpdated$: Subject<void> = new Subject()

  get Columns() {
    return Columns
  }

  get PaymentTypes() {
    return PaymentTypes
  }

  get PaidStatus() {
    return PaidStatus
  }

  get OrderStatus() {
    return OrderStatus
  }

  get DateFormat() {
    return DateFormat
  }

  constructor(
    private router: Router,
    private store: Store,
    private utils: UtilsService,
    private economyTabService: EconomyTabService
  ) {
    super()
  }

  ngOnInit() {
    this.table.data$ = this.getUnpaidPayments()
  }

  getUnpaidPayments(): Observable<IndividualPaymentResponse[]> {
    const resetPagination$ = this.table.sorting$.subscribe(() => (this.table.page = 1))

    return combineLatest([
      this.table.sorting$,
      this.table.paging$.pipe(startWith(null)),
      this.dataUpdated$.pipe(startWith(null))
    ]).pipe(
      tap(() => (this.table.loading = true)),
      switchMap(([sorting]) => {
        return this.economyTabService
          .getIndividualPayments({
            groupId: this.organization.id,
            filters: {
              [FilterNames.PaidStatus]: [PaidStatusFilterValues.NotPaid],
              [FilterNames.UserId]: this.hostComponent.user!.id
            },
            sorting,
            page: this.table.page
          })
          .pipe(
            catchError((e: HttpErrorResponse) => {
              if (e.error.code === Constants.invalidPageError) {
                this.table.page -= 1
                this.table.paging$.next()
              }
              return of(Constants.getEmptyPagination())
            })
          )
      }),
      map((response) => {
        this.table.rowsTotal = response.count
        return response.results
      }),
      tap((payments) => {
        this.table.loading = false
        this.table.rowsCount = payments.length
      }),
      finalize(() => resetPagination$.unsubscribe())
    )
  }

  goToIndividualPaymentsPage(): void {
    this.store
      .dispatch(new ToggleUserProfile(false))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        // Wait for the artificial history state to be reset before triggering the redirect.
        this.router.events
          .pipe(
            filter((event) => event instanceof NavigationSkipped),
            first()
          )
          .subscribe(() => {
            this.store.dispatch(
              new Navigate(['dashboard/economy/individual-payments/'], {
                userId: this.hostComponent.user!.id,
                firstName: this.hostComponent.user!.first_name,
                lastName: this.hostComponent.user!.last_name
              })
            )
          })
      })
  }

  onSort(sorting: Sort): void {
    this.table.sorting$.next(sorting.direction ? sorting : null)
  }

  openNewReminder(row: IndividualPaymentResponse): void {
    this.selectedPayment = row
    this.isNewReminderOpen = true
  }

  closeNewReminder(shouldRefreshTable: boolean): void {
    this.isNewReminderOpen = false
    this.selectedPayment = null

    if (shouldRefreshTable) {
      this.dataUpdated$.next()
    }
  }

  openReminderHistory(row: IndividualPaymentResponse): void {
    this.selectedPayment = row
    this.isReminderHistoryOpen = true
  }

  closeReminderHistory(shouldOpenNewReminder: boolean): void {
    this.isReminderHistoryOpen = false

    if (shouldOpenNewReminder) {
      this.isNewReminderOpen = true
    } else {
      this.selectedPayment = null
    }
  }

  openNewInvoice(row: IndividualPaymentResponse): void {
    this.selectedPayment = row
    this.isNewInvoiceOpen = true
  }

  closeNewInvoice(shouldRefreshTable: boolean): void {
    this.isNewInvoiceOpen = false
    this.selectedPayment = null

    if (shouldRefreshTable) {
      this.dataUpdated$.next()
    }
  }

  openInvoiceHistory(row: IndividualPaymentResponse): void {
    this.selectedPayment = row
    this.isInvoiceHistoryOpen = true
  }

  closeInvoiceHistory(shouldGenerateNewInvoice: boolean): void {
    this.isInvoiceHistoryOpen = false

    if (shouldGenerateNewInvoice) {
      this.isNewInvoiceOpen = true
    } else {
      this.selectedPayment = null
    }
  }

  openUserPaymentDetails(id: number): void {
    this.utils.redirectUserTo(`/dashboard/economy/individual-payments/${id}`, new ToggleUserProfile(false), true)
  }

  openEditUserPayment(row: IndividualPaymentResponse): void {
    this.selectedPayment = row
    this.isEditUserPaymentOpen = true
  }

  closeEditUserPayment(shouldRefreshTable: boolean): void {
    this.isEditUserPaymentOpen = false
    this.selectedPayment = null

    if (shouldRefreshTable) {
      this.dataUpdated$.next()
    }
  }
}
